<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">订单统计
      <div class="hk_page_title_right">
        <el-button type="primary" size="small" plain @click="exportToLocal">导出</el-button>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">

          <div class="hk_search_box_item">
            达人标签：
            <el-select v-model="searchTag" size="small" clearabl multiple collapse-tags placeholder="请选择">
              <!-- <el-option label="全部" value="">全部</el-option> -->
              <el-option :label="item" :value="item" :key="idx" v-for="(item,idx) in netKoiList"></el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            渠道：
            <el-select v-model="searchChannel" size="small" clearable placeholder="请选择">
              <el-option label="全部" value="">全部</el-option>
              <el-option :label="chanelEnum[txt]" :value="txt" :key="key" v-for="(txt,key) in channelList">{{ chanelEnum[txt]}}</el-option>

              <!-- <el-option label="抖音" value="DOU_YIN">抖音</el-option>
              <el-option label="小红书" value="XIAO_HONG_SHU">小红书</el-option> -->

            </el-select>

          </div>
          <div class="hk_search_box_item">
            学期：
            <el-select v-model="searchTerm" clearable size="small" placeholder="请选择">
              <el-option :label="txt" :value="txt" :key="key" v-for="(txt,key) in termList">{{ txt}}</el-option>
            </el-select>
          </div>
          <div class="hk_search_box_item">
            学科：
            <el-select v-model="searchCategory" clearable collapse-tags size="small" placeholder="请选择">
              <el-option :label="txt" :value="txt" :key="key" v-for="(txt,key) in categoryList">{{ txt}}</el-option>
            </el-select>

          </div>

          <div class="hk_search_box_item">
            时间：
            <el-date-picker v-model="searchTime" size="small" class="ipt-date-suffix" type="daterange" clearable range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchEmployeeFun">搜索</el-button>
            <el-button type="default" size="small" @click="resetSearchFun">重置</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索结果共计课程数 <span class="hk-font-highlight">{{ totalCouseNum }}单</span>，营业额<span class="hk-font-highlight">￥{{ totalPayAmount }}</span>，退费总额<span class="hk-font-highlight">￥{{ totalRefundAmount }}</span>，实收总额<span class="hk-font-highlight">￥{{ totalRealAmount }}</span>
        </div>

        <div class="hk_custom_table">
          <el-table show-summary :summary-method="getSummaries" ref="myTable" :data="tableData" style="width: 100%">
            <el-table-column label="统计日期" width="150px">
              <template slot-scope="scope">
                <span>{{ scope.row.orderDate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="学科" width="120px">
              <template slot-scope="scope">
                <span>{{ scope.row.category }}</span>
              </template>
            </el-table-column>
            <el-table-column label="渠道" width="120px" prop="channel">
              <template slot-scope="scope">
                <i class="iconfont icon-douyin" style="color:#333;" v-if="scope.row.channel=='DOU_DIAN'"></i>
                <i class="iconfont icon-xiaohongshu" style="color:#FF0035;" v-if="scope.row.channel=='XIAO_HONG_SHU'"></i>
                <span>{{ chanelEnum[scope.row.channel] }} </span>
              </template>
            </el-table-column>
            <el-table-column label="达人标签" width="140px">
              <template slot-scope="scope">
                <span>{{ scope.row.kolName }}</span>
              </template>
            </el-table-column>

            <el-table-column label="学期" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.coursePackage||'-'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="课程数" width="120px" prop="orderNum">
              <template slot-scope="scope">
                <span>{{ scope.row.orderNum }} </span>
              </template>
            </el-table-column>
            <el-table-column label="营业总额/￥" :min-width="150" prop="payAmount">
              <template slot-scope="scope">
                <span> {{ (scope.row.payAmount || 0)/100 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退费总额/￥" width="150px" prop="refundAmount">
              <template slot-scope="scope">
                <span> {{ (scope.row.refundAmount || 0)/100 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="实收总额/￥" width="150px" prop="realAmount">
              <template slot-scope="scope">
                <span> {{ (scope.row.realAmount || 0)/100 }}</span>
              </template>
            </el-table-column>

            <div slot="empty">
              <p>暂无信息</p>
            </div>
          </el-table>
          <!-- <div class="hk_pagination" v-if="totalNum>0">
            <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
            </el-pagination>
          </div> -->
        </div>
        <div class="hk_custom_scroll">
          <div ref="hkCustomScroll" class="hk-scroll" @scroll="handleScrollTop">
            <div class="hk-scroll-content" :style="'width:'+tableWidth+'px'"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { SubjectTypeEnum, TermEnum, ChanelEnum } from '../../utils/enums';
export default {
  data() {
    return {
      tableWidth: 0, //table内容的实际宽度，赋值给自定义滚动条

      searchTag: [],//搜索条件--达人标签
      searchChannel: '', //渠道
      searchTerm: '', //学期
      searchCategory: '', //学科
      searchTime: [], //搜索条件--时间

      netKoiList: [], //达人列表
      channelList: [], //渠道列表
      termList: [], //学期列表，春夏秋冬
      categoryList: [], //学科列表，美育、思维等

      chanelEnum: ChanelEnum, //渠道

      tableData: [], //列表中显示的数据(搜索后,只显示符合条件的数据)

      totalCouseNum: 0,//课程总数
      totalPayAmount: 0, //营业总额
      totalRefundAmount: 0, //退款总额
      totalRealAmount: 0, //实收总额

    }
  },
  mounted() {
    this.getStatisticParamsFun()
    this.getDataListFun()
  },
  methods: {
    //自定义滚动条的事件
    handleScrollTop(e) {
      let that = this;
      let tmpLeft = e.target.scrollLeft
      if (that.$refs["myTable"]) {
        that.tableDom = that.$refs["myTable"].bodyWrapper
        that.tableDom.scrollTo(tmpLeft, 0)

      }
    },

    //导出
    exportToLocal() {
      let that = this;

      that.requestDownloadGet({
        url: that.interfacePre() + '/manage/export/commonExport?exportType=ORDER_STATISTIC',
        success: function (result) {
          // console.log(result, '------导出')

          var elink = document.createElement('a');
          // 使用后端提供的文件名  命名
          const fileName = result.headers['content-disposition'].split('="')[1];
          elink.style.display = 'none';
          // 文件下载地址赋值
          elink.href = window.URL.createObjectURL(result.data);
          let str = decodeURIComponent(fileName);
          str = str.substring(0, str.length - 1);
          // 自定义文件名
          elink.download = str;
          // 调用a标签单击事件进行下载文件
          elink.click();

          that.$message('导出成功')

        },
        error: function (error) {
          //服务器异常
          that.$message.error('导出失败。' + error)
        }
      })
    },

    // //获取达人列表
    // getNetKoiFun() {
    //   let that = this;
    //   that.request({
    //     method: 'post',
    //     url: '/manage/product/listKoi',
    //     param: {},
    //     success: function (result) {
    //       if (result.code == 200) {
    //         that.netKoiList = result.result || [];
    //       } else {
    //         console.log(result, '----达人 200')
    //       }
    //     },
    //     error: function (error) {
    //       //服务器异常
    //       console.log(error)
    //       that.$message.error('获取达人列表失败。')
    //     }
    //   })
    // },

    //获取统计参数列表
    getStatisticParamsFun() {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/product/getStatisticParams',
        param: {},
        success: function (result) {
          if (result.code == 200) {
            let tmpResult = result.result || {}
            that.channelList = tmpResult.channelList || []
            that.netKoiList = tmpResult.koiList || []
            that.categoryList = tmpResult.categoryList || [] //学科
            that.termList = tmpResult.coursePackageList || []

          } else {
            console.log(result, '----达人 200')
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error)
          that.$message.error('获取达人列表失败。')
        }
      })
    },

    //重置搜索条件
    resetSearchFun() {
      this.searchTag = []; //搜索条件--达人标签
      this.searchChannel = '' //渠道
      this.searchTerm = '' //学期
      this.searchCategory = '' //学科
      this.searchTime = []
      this.searchEmployeeFun()
    },

    //搜索
    searchEmployeeFun() {
      this.tableData = []
      this.getDataListFun()
    },
    //获取数据列表，不分页
    getDataListFun() {
      let that = this;
      let param = {
        // pn: that.curPage,
        // ps: that.pageSize,

      };

      if (that.searchTag) { //达人
        param.kolNameList = that.searchTag
      }
      if (that.searchChannel) { //渠道
        param.channel = that.searchChannel
      }
      if (that.searchTerm) { //学期、课包
        param.coursePackage = that.searchTerm
      }
      if (that.searchCategory) { //学科
        param.category = that.searchCategory
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }

      that.totalCouseNum = 0
      that.totalPayAmount = 0
      that.totalRealAmount = 0
      that.totalRefundAmount = 0
      that.tableData = [] //先将数据清空，避免视图不及时更新
      that.request({
        method: 'post',
        url: '/manage/order/orderStatistic',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            // let data = result.result;
            that.tableData = result.result || [];
            //设置滚动
            setTimeout(() => {
              if (that.$refs["myTable"]) {
                that.tableWidth = that.$refs["myTable"].bodyWrapper.scrollWidth + 50 //没有fixed或者fixed=right,发现宽度少了
                that.$refs["myTable"].doLayout()
                that.tableDom = that.$refs["myTable"].bodyWrapper
                that.tableDom.addEventListener('scroll', () => {
                  let scrollLeft = that.tableDom.scrollLeft
                  // console.log('table scroll----', scrollLeft)
                  that.$refs.hkCustomScroll.scrollTo(scrollLeft, 0)
                })
              }
            }, 300)
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单列表失败。')
          console.log(error, '-----fenye 获取订单失败')
        }
      })
    },

    //table表格的汇总数据
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // console.log(column, '-----columen---', index)
        // console.log(data, '-----data---', index)
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        const values = data.map(item => Number(item[column.property]));
        // console.log(values, '=======values')
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          console.log(column.property, '-------column.property')
          let tmpSum = sums[index]
          if (column.property == 'orderNum') {
            that.totalCouseNum = tmpSum
          }
          if (column.property == 'payAmount') {
            that.totalPayAmount = tmpSum / 100
            sums[index] = '￥ ' + tmpSum / 100;
          }
          if (column.property == 'refundAmount') {
            that.totalRefundAmount = tmpSum / 100
            sums[index] = '￥ ' + tmpSum / 100;
          }
          if (column.property == 'realAmount') {
            that.totalRealAmount = tmpSum / 100
            sums[index] = '￥ ' + tmpSum / 100;
          }

          // console.log(sums, '=======Yuan')
        } else {
          sums[index] = '/';
        }
      });

      return sums;
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getDataListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getDataListFun();
    },


  }
}
</script>

<style lang="scss" scoped>
// 表格中的商品信息
.red-product-show {
  .rps-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .rps-img {
      width: 91px;
      height: 91px;
    }
    .rps-item-info {
      flex: 1;
      padding-left: 8px;
      font-family: PingFangSC, PingFang SC;
      .info-title {
        font-weight: 600;
        font-size: 14px;
        color: #000;
        line-height: 22px;
        padding-bottom: 5px;
      }
      .info-txt {
        font-weight: 400;
        font-size: 12px;
        color: #606060;
        line-height: 20px;
        display: flex;

        .txt-right {
          width: 50px;
          padding-left: 20px;
          font-size: 12px;
          color: #999999;
          letter-spacing: 2px;
        }
      }
    }
  }
}

//导入弹窗
.export-dialog {
  .export-main {
    padding-bottom: 20px;
    .export-upload {
      display: flex;
      align-items: center;
      .d-template {
        color: #f08d00;
      }
    }
    .export-remark {
      display: flex;
      align-items: center;
      padding-top: 8px;
      .ipt-remark {
        width: 200px;
        margin-right: 6px;
      }
    }
  }
  .export-record {
    .record-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding-bottom: 12px;
    }
    .record-item-list {
      max-height: 480px;
      overflow: auto;
    }
    .record-item {
      font-weight: 400;
      font-size: 14px;
      color: #f08d00;
      line-height: 22px;
      text-align: left;
      .span {
        color: rgba(0, 0, 0, 0.85);
        padding: 0 4px;
      }
    }
  }
}

//将el-table的横向滚动条给隐藏,只能写在每个页面，写在公共里面无效
.hk_custom_table {
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
}
</style>